
export const getAPIURL = () => {

    // const apiUrl = 'http://localhost/omniwheels/bb-school-api/';
    //  const apiUrl = 'https://api.school-dev.bluebottless.com/';
    // const apiUrl = 'https://api.school-uat.bluebottless.com/';
    const apiUrl = 'https://api.school.bluebottless.com/';
    return apiUrl;
}

// getLicenseKey
export const getLicenseKey = 5566;

// export const webUrl = 'https://school-dev.bluebottless.com/';
export const webUrl = 'https://school.bluebottless.com/';